<template>
  <div class="body-box">
    <div style="align-items:baseline;margin-bottom: 10px;" class="flex">
      <div :class="isExpand ? 'expand course-box' : 'course-box'">
        <div v-for="item, index in courseList" :key="index"
          :class="selectIndex === index ? 'course-pane ellipsis select' : 'course-pane ellipsis'"
          @click="chooseCourse(item.id, index)">
          {{ item.kcName }}
        </div>
      </div>
      <div v-if="isExpand" class="expand-btn" @click="expand">全部 <i class="el-icon-arrow-down" /></div>
      <div v-else class="expand-btn" @click="expand">收起 <i class="el-icon-arrow-up" /></div>
    </div>
    <div v-for="item, index in dateList" :key="index" style="margin-top: 10px;">
      <div class="flex date-box" @click="changeDate(index)">
        <div @click.stop="dataValueDio = true" class="flex center"><img class="date-icon"
            src="@/assets/img/partner/date.png"><span>{{ item }}</span><span class="change-date-icon"><i
              class="el-icon-sort" /></span></div>
        <div v-if="index === dateShow">
          <div class="expand-btn"><i class="el-icon-arrow-up" /></div>
        </div>
        <div v-else>
          <div class="expand-btn"><i class="el-icon-arrow-down" /> </div>
        </div>
      </div>
      <div v-if="index === dateShow" class="subject-body">
        <div v-for="item, index in topicList" :key="index">
          <div class="flex between subject-item">
            <div>
              <span :style="subjectColor[item.typeName]" class="subject-type">{{ item.typeName }}</span>
              <span class="subject-title">{{ item.paperName }}</span>
            </div>
            <div>
              <span><i class="el-icon-time" /></span>
              <span>用时：</span>
              <span>{{ item.hour }}:{{ item.minute }}:{{ item.second }}</span>
            </div>
          </div>
          <div class="flex between subject-item">
            <div>
              <span><i class="el-icon-time" /></span>
              <span>{{ item.createTime }}</span>
            </div>
            <div class="flex center">
              <span><img src="@/assets/img/partner/accuracy.png"></span>
              <span class="accuracy">正确率{{ item.accuracy }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog width="400px" center="" :visible.sync="dataValueDio">
      <div v-if="dataValueDio">
        <el-date-picker @change="datachange()" value-format="yyyy-MM-dd" v-model="dataValue" type="date"
          placeholder="选择日期">
        </el-date-picker>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getCourseList, getDateList, getStudentPaperResultVoList } from '@/api/course/course'
export default {
  data() {
    return {
      courseList: [], // 课程列表
      dataValueDio: false,
      dataValue: '',
      dateList: [], // 日期列表
      topicList: [], // 选中日期做题记录
      isExpand: true, // 是否展开
      selectId: 0, // 选中课程
      selectIndex: 0, // 选中课程下标
      dateShow: 0, // 选中日期下标
      subjectColor: {
        '随堂练习': {
          color: '#FF5E51',
          background: '#FFEEED'
        },
        '历年真题': {
          color: '#C275FC',
          background: '#F9F1FF'
        },
        '考点练习': {
          color: '##3789FF',
          background: '#EAF3FF'
        },
        '章节练习': {
          color: '#14D3D9',
          background: '#E7FBFB'
        },
        '课程模考': {
          color: '#5AB776',
          background: '#EEF8F1'
        },
        '入学测试': {
          color: '#EEF8F1',
          background: '#FFF8ED'
        },
        '考前押题': {
          color: '#5174FF',
          background: '#EDF1FF'
        },
        '易错考题': {
          color: '#FF7070',
          background: '#FFF0F0'
        },
        '模拟试卷': {
          color: '#DED445',
          background: '#FCFBEC'
        }
      }
    }
  },
  // async created() {
  //   await this.getCourseList()
  // },
  mounted() {
    this.getCourseList()
  },
  methods: {
    datachange() {
      console.log(this.dataValue, 'dataValue');
      const index = this.dateList.indexOf(this.dataValue);
      console.log(index, 'index');
      if (index != -1) {
        this.changeDate(index)
      } else {
        this.$message({
          message: '当天暂无数据',
          type: 'warning'
        });
      }
      this.dataValueDio = false
      this.dataValue = ''
    },
    // 获取课程列表
    async getCourseList() {
      const res = await getCourseList()
      if (res.code === 0) {
        this.courseList = res.data
        this.chooseCourse(this.courseList[0].id, 0)
      }
    },
    // 展开头部课程列表
    expand() {
      this.isExpand = !this.isExpand
    },
    // 选择课程
    async chooseCourse(id, index) {
      this.selectId = id
      this.selectIndex = index
      const res = await getDateList({ courseId: id })
      if (res.code === 0) {
        this.dateList = res.data
        this.changeDate(0)
      }
    },
    // 展开日期 or 选择日期
    changeDate(index) {
      this.dateShow = index
      this.chooseDate(this.selectId, this.dateList[index])
    },
    // 请求当日做题记录
    async chooseDate(id, date) {
      const data = {
        courseId: id,
        dayTime: date
      }
      await getStudentPaperResultVoList(data).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.topicList = res.data.map((item) => {
            return {
              ...item,
              hour: String(Math.floor(item.answerTimes / 3600)).padStart(2, '0'),
              minute: String(Math.floor((item.answerTimes % 3600) / 60)).padStart(2, '0'),
              second: String(item.answerTimes % 60).padStart(2, '0')
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}

.center {
  align-items: center;
  text-align: center;
}

.between {
  justify-content: space-between;
}

.body-box {
  width: 100%;
  margin-top: 25px;

  .course-box {
    display: flex;
    flex-wrap: wrap;

    .course-pane {
      background: #FFFFFF;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #777777;
      border: 1px #777777 solid;
      min-width: 60px;
      margin: 5px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
    }

    .select {
      color: #2586F5;
      border: 1px #2586F5 solid;
    }
  }

  .expand-btn {
    font-size: 14px;
    font-family: Microsoft YaHei-Semibold, Microsoft YaHei;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
    min-width: 40px;
    color: #333333;
    flex-shrink: 0;
  }

  .expand {
    height: 40px;
    overflow: hidden;
  }

  .date-box {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    width: 100%;
    padding: 8px 10px;
    background: #F5F7F9;
    justify-content: space-between;

    .change-date-icon {
      border: 1px solid rgb(51, 51, 51);
      font-size: 14px;
      line-height: 14px;
    }

    img {
      width: 14px;
      height: 14px;
    }

    span {
      margin-left: 5px;
    }
  }

  .subject-body {

    // border-bottom: 1px solid #eeeeee;
    >div {
      border-bottom: 1px solid #eeeeee;

    }

    .subject-type {
      padding: 3px 5px;
    }

    .subject-color {
      color: #FF5E51;
      background: #FFEEED;
    }

    .subject-title {
      color: #333333;
      font-size: 14px;
    }

    .accuracy {
      font-size: 14px;
      color: #2586F5;
    }

    .subject-item {
      margin: 10px 0px;
    }

    span {
      color: #999999;
      font-size: 12px;
      // margin-left: 5px;
    }

    img {
      width: 11.4px;
      height: 13px;
    }
  }
}

::v-deep .el-dialog {
  background: transparent;
  box-shadow: 0 0 0 0;

}

::v-deep .el-dialog__headerbtn {
  display: none;
}
</style>
